import React, { useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom'
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css';
import MemberInput from '../MemberInput/MemberInput';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/images/CalamariOil-webp.webp';

function JSASubmitted({ onClose, onClick }) {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);
    }, [navigate])
    return (
        <>
            <div className='jsa-popup-bg'>
                <div className='ready-check-container'>
                    <p className='jsa-submit-confirmation'>JSA Has Been Submitted</p>
                </div>
            </div>
        </>
    )
}

export default function JSALogForm(props) {
    const [isOpen, setIsOpen] = useState(false);
    let [ index, setIndex ] = useState(0);

    //////////////////////////// Prepopulated Data Logic ////////////////////////////////////////
    const location = useLocation();

    const [jsaFormData, setJsaFormData] = useState({
        ticketNumber: '',
        company: '',
        workActivityDescription: '',
        workSequence: '',
        hazards: '',
        reduceHazards: '',
        members: []  // Make sure this is part of your initial state if it's used
    });
    const [memberInputs, setMemberInputs] = useState([{
        name: '',
        ppe: {}
    }]);

    // Fetch the most recent JSA when the component mounts if instructed by the previous screen
    const fetchMostRecentJSA = useCallback(async () => {
        const userId = 1; // Update with logic to get user ID
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jsa/latest?user_id=${userId}`);
            const data = await response.json();
            if (response.ok && data.length > 0) {
                populateForm(data[0].jsa_data);
            } else {
                console.log('No recent JSA found');
            }
        } catch (error) {
            console.error('Error fetching latest JSA:', error);
        }
    }, []);

    useEffect(() => {
        if (location.state?.loadRecentJSA) {
            fetchMostRecentJSA();
        }
    }, [location.state, fetchMostRecentJSA]);
    
    const populateForm = (data) => {
        const parsedData = JSON.parse(data);
        setJsaFormData({
            ticketNumber: parsedData.ticketNumber || '',
            company: parsedData.company || '',
            workActivityDescription: parsedData.workActivityDescription || '',
            workSequence: parsedData.workSequence || '',
            hazards: parsedData.hazards || '',
            reduceHazards: parsedData.reduceHazards || '',
        });
        setMemberInputs(parsedData.members || [{ name: '', ppe: {} }]);
    };
    

    useEffect(() => {
        console.log("Current form data:", jsaFormData);
    }, [jsaFormData]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setJsaFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleJSASubmit = () => {
        setIsOpen(true);
        generatePDF();
        submitJSA();
    };

    ////////////////////////////  JSA PDF and Logic ///////////////////////////////

    const addMemberInput = () => {
        setMemberInputs([...memberInputs, { name: '', ppe: {} }]);
    };

    const removeLastMemberInput = () => {
        if (memberInputs.length > 1) {
            setMemberInputs(memberInputs.slice(0, -1));
        }
    };

    const updateMember = (index, data) => {
        const newInputs = [...memberInputs];
        newInputs[index] = data;
        setMemberInputs(newInputs);
    };

    const loadImage = async () => {
        const response = await fetch(logo);
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
    };

    const generatePDF = async () => {
        const doc = new jsPDF();
        // const imageData = await loadImage();
        // doc.addImage(imageData, 'WEBP', 150, 15, 50, 30);
        const { ticketNumber, company, workActivityDescription, workSequence, hazards, reduceHazards } = jsaFormData;
    
        doc.setFontSize(16);
        doc.text('JSA Report', 10, 20);
        doc.setFontSize(12);
        doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 30);
        doc.text(`Ticket Number: ${ticketNumber}`, 10, 40);
        doc.text(`Company: ${company}`, 10, 50).setFont(undefined, 'bold');
        doc.text('Work Activity Description:', 10, 60).setFont(undefined, 'normal');
        doc.text(`${workActivityDescription}`, 10, 70).setFont(undefined, 'bold');
        doc.text(`Work Sequence:`, 10, 90).setFont(undefined, 'normal');
        doc.text(`${workSequence}`, 10, 100).setFont(undefined, 'bold');
        doc.text(`Hazard or Potential Accidents:`, 10, 120).setFont(undefined, 'normal');
        doc.text(`${hazards}`, 10, 130).setFont(undefined, 'bold');
        doc.text(`Recommendations to Eliminate or Reduce Hazard:`, 10, 150).setFont(undefined, 'normal');
        doc.text(`${reduceHazards}`, 10, 160);
    
        // Member PPE Selections
        const tableColumnNames = ["Members of Work Team", "Steel Toe Boots", "Hard Hat", "Safety Glasses", "FRC'S", "Impact Gloves"];

        // Styles for the table
        const styles = { 
            halign: 'center',
            fillColor: [211, 211, 211] // Light gray background for header
        }; // Default style for all cells

        const columnStyles = { 
            0: { 
                halign: 'left' 
            } 
        }; // Override for the first column to be left-aligned

        const headStyles = { 
            fillColor: [0, 114, 109], // Dark blue color for the table headers
            textColor: [255, 255, 255], // White text color for the headers
        };

        doc.autoTable({
            head: [tableColumnNames],
            body: memberInputs.map(member => [
                member.name,
                member.ppe["steel-toe-boots"] ? "Yes" : "",
                member.ppe["hard-hat"] ? "Yes" : "",
                member.ppe["safety-glasses"] ? "Yes" : "",
                member.ppe["frcs"] ? "Yes" : "",
                member.ppe["impact-gloves"] ? "Yes" : ""
            ]),
            styles,
            columnStyles,
            headStyles,
            startY: 180,
            margin: { left: 10 } // Ensure the table starts at the same left margin as the rest of the text
        });
        doc.save('JSA_Report.pdf');
    };


    //////////////////////////////// POST JSA Form and Logic //////////////////////////////////////
    const submitJSA = async () => {
        const jsaData = {
            ticketNumber: jsaFormData.ticketNumber,
            company: jsaFormData.company,
            workActivityDescription: jsaFormData.workActivityDescription,
            workSequence: jsaFormData.workSequence,
            hazards: jsaFormData.hazards,
            reduceHazards: jsaFormData.reduceHazards,
            members: memberInputs
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jsa`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: 1, jsa_data: jsaData })
            });
            if (!response.ok) {
                throw new Error('Failed to submit JSA: ' + response.status);
            }
            const responseData = await response.json();  // Ensure response is parsed as JSON
            console.log('JSA submitted successfully', responseData);
        } catch (error) {
            console.error('Error submitting JSA:', error);
        }
    };

    /////////////////////////////////// Render Logic /////////////////////////////////
    if (!isOpen) document.body.style.removeProperty('overflow');
    else document.body.style.overflow = 'hidden';

    if (index===0) {
        return (
            <>
                <div className='log-info-container'>
                    <h1 className='site-log-title'>JSA</h1>
                    <p className='log-name'>{new Date().toLocaleDateString()}</p>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Ticket Number*</h2>
                    <form className='jsa-input-form'>
                        <input 
                            className='jsa-input' 
                            name='ticketNumber'
                            value={jsaFormData.ticketNumber} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Company*</h2>
                    <form className='jsa-input-form'>
                        <input 
                            className='jsa-input' 
                            name='company'
                            value={jsaFormData.company} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Work Activity Description*</h2>
                    <form className='jsa-input-form'>
                        <input 
                            className='jsa-input' 
                            name='workActivityDescription'
                            value={jsaFormData.workActivityDescription} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Work Sequence</h2>
                    <form className='jsa-input-form-long'>
                        <input 
                            className='jsa-input' 
                            name='workSequence'
                            value={jsaFormData.workSequence} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Hazard or Potential Accidents</h2>
                    <form className='jsa-input-form-long'>
                        <input 
                            className='jsa-input' 
                            name='hazards'
                            value={jsaFormData.hazards} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='jsa-input-container'>
                    <h2 className='jsa-input-title'>Recommendations to eleminate or reduce hazard</h2>
                    <form className='jsa-input-form-long'>
                        <input 
                            className='jsa-input' 
                            name='reduceHazards'
                            value={jsaFormData.reduceHazards} 
                            onChange={handleInputChange} 
                            placeholder='Type...'>
                        </input>
                    </form>
                </div>
                <div className='log-status-container'>
                    <div className='submit-jsa-btn' onClick={event => setIndex(index+1)}>
                        <p className='up-next-status'>Next</p>
                    </div>
                </div>
            </>
        )
    } else if (index===1) {
        return (
            <>
                <div className='jsa-popup-bg'>
                    <h1 className='jsa-popup-title'>Has the team changed?</h1>
                    <div className='ready-check-container'>
                        <div className='confirm-btn' onClick={event => setIndex(index+1)}>
                            <span>Yes</span>
                        </div> 
                        <div className='cancel-btn' onClick={event => setIndex(index+1)}>
                            <span>No</span>
                        </div> 
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='log-info-container'>
                    <h1 className='site-log-title'>JSA (cont.)</h1>
                    <p className='log-name'>{new Date().toLocaleDateString()}</p>
                </div>
                <div className='jsa-input-container-member'>
                    <h2 className='jsa-input-title'>Members of Work Team</h2>
                    {memberInputs.map((input, index) => (
                        <MemberInput 
                            key={index} 
                            index={index} 
                            memberData={input}
                            onUpdate={updateMember}
                        />
                    ))}
                    <div className='member-btn-container'>
                        <button className='add-member-btn' onClick={addMemberInput}>Add Member</button>
                        <button className='remove-member-btn' onClick={removeLastMemberInput}>Remove Member</button>
                    </div>
                </div>
                <div className='log-status-container'>
                    <div className='submit-jsa-btn' onClick={handleJSASubmit}>
                        <p className='up-next-status'>Submit JSA</p>
                    </div>
                    {isOpen && createPortal(
                        <JSASubmitted onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
                        document.getElementById('ready-check')
                    )}
                </div>
            </>
        )
    }
}