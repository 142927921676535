import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { BackIcon, DropdownArrow } from '../../assets/icons/icons';

export default function MemberInput({ index, memberData, onUpdate }) {
    const [memberName, setMemberName] = useState(memberData.name);
    const [selectedPPE, setSelectedPPE] = useState(memberData.ppe);
    const [showCheckboxes, setShowCheckboxes] = useState(false);

    const dropdownRef = useRef(null);

    const toggleCheckboxes = () => {
        setShowCheckboxes(prev => !prev);
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        const updatedPPE = { ...selectedPPE, [id]: checked };
        setSelectedPPE(updatedPPE);
        onUpdate(index, { name: memberName, ppe: updatedPPE });
    };

    const handleNameChange = (event) => {
        setMemberName(event.target.value);
        onUpdate(index, { name: event.target.value, ppe: selectedPPE });
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowCheckboxes(false);
        }
    };

    const handleTouchOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowCheckboxes(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleTouchOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleTouchOutside);
        };
    }, []);

    return (
        <div className='jsa-input-form-member' id='member-form'>
            <input 
                id='member-input' 
                className='jsa-input'
                value={memberName}
                onChange={handleNameChange}
                placeholder="Member Name..."
            />
            <div className='dropdown' ref={dropdownRef}>
                <button 
                    type='button' 
                    className='dropdown-header' 
                    onClick={toggleCheckboxes}
                >
                    Select PPE <DropdownArrow className='dropdown-arrow'/>
                </button>
                {showCheckboxes && (
                    <div className='ppe-checkboxes show'>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={selectedPPE['steel-toe-boots'] || false} 
                                onChange={handleCheckboxChange} 
                                id="steel-toe-boots" 
                            />
                            Steel Toe Boots
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={selectedPPE['hard-hat'] || false} 
                                onChange={handleCheckboxChange} 
                                id="hard-hat" 
                            />
                            Hard Hat
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={selectedPPE['safety-glasses'] || false} 
                                onChange={handleCheckboxChange} 
                                id="safety-glasses" 
                            />
                            Safety Glasses
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={selectedPPE['frcs'] || false} 
                                onChange={handleCheckboxChange} 
                                id="frcs" 
                            />
                            FRC'S
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={selectedPPE['impact-gloves'] || false} 
                                onChange={handleCheckboxChange} 
                                id="impact-gloves" 
                            />
                            Impact Gloves
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
}
