import React, { useState, useEffect }from 'react';
import { createPortal } from 'react-dom'
import './style.css'
import { BackIcon, SiteInfoIcon, WellIcon } from '../../assets/icons/icons';


function SiteInfoWindow({ onClose, onClick }) {

    const [info, setInfo] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setInfo(data[2]))
        .catch(error => console.error('Error:', error));
    }, []);
    return (
        <>  
            <div className='site-info-bg'>
            <div className='back-icon-container'>
                <BackIcon onClick={onClose}/>
            </div>
                <WellIcon/>
                <div className='site-info-container'>
                    <p className='site-info-title'> Site Name: <span className='site-info-text'>{info.name}</span></p>
                    <p className='site-info-title'> Site Owner: <span className='site-info-text'>{info.owner}</span></p>
                    <p className='site-info-title'> Company Man: <span className='site-info-text'>Joey Calamari</span></p>
                    <p className='site-info-title'> Address: <span className='site-info-text'>{info.address}, {info.city}, {info.state}</span></p>
                    <p className='site-info-title'> Coordinates: <span className='site-info-text'>35.78266062523847, -98.59605789745964</span></p>
                    <p className='site-info-title'> Wells: <span className='site-info-text'>16</span></p>
                </div>
            </div>
        </>
    )
}

export default function SiteInfo(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    if (!isOpen) {
        document.body.style.removeProperty('overflow')
    }
    else {
        document.body.style.overflow = 'hidden'
    }

    return (
        <>
            <SiteInfoIcon className='site-info-icon' style={props.position} onClick={() => setIsOpen(true)}/>
            {isOpen && createPortal(
                <SiteInfoWindow onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
                document.getElementById('site-info')
            )}
        </>
    );
}