import React, {useEffect, useState} from 'react';
import { HomeIcon, HomeIconFilled, CheckIcon, CheckIconFilled, SettingsIcon, SettingsIconFilled } from '../../assets/icons/icons';
import './style.css'

export default function NavHub(props) {
   
    let [ Home, setHome ] = useState(false)
    let [ ReadyCheck, setReadyCheck] = useState(false)
    let [ Settings, setSettings ] = useState(false)

    useEffect (() => { 
        if ( window.location.pathname==='/' ) {
            setHome(true)
        } else if ( window.location.pathname==='/ready-check') {
            setReadyCheck(true)
        } else if ( window.location.pathname==='/settings') {
            setSettings(true)
        }
    }, [])

    return (
        <>
            <div className='navhub-container'>
                {/* <span className={Home ? 'home-icon' : 'home-icon-filled'} onClick={event => window.location.href='/'}>HOME</span>
                <span className={ReadyCheck ? 'home-icon' : 'home-icon-filled'} onClick={event => window.location.href='/ready-check'}>READY CHECK</span>
                <span className={Settings ? 'home-icon' : 'home-icon-filled'} onClick={event => window.location.href='/'}>SETTINGS</span> */}
                {Home ? <HomeIconFilled className='nav-icon' onClick={event => window.location.href='/'}/> : <HomeIcon className='nav-icon' onClick={event => window.location.href='/'}/>}
                {ReadyCheck ? <CheckIconFilled className='nav-icon' onClick={event => window.location.href='/ready-check'}/> : <CheckIcon className='nav-icon' onClick={event => window.location.href='/ready-check'}/>}
                {Settings ? <SettingsIconFilled className='nav-icon' onClick={event => window.location.href='/'}/> : <SettingsIcon className='nav-icon' onClick={event => window.location.href='/'}/>}
            </div>
        </>
        // Need to add dot logic for active pages
    );
}