import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SiteInfo from './pages/SiteInfo';
import SiteLog from './pages/SiteLog';
import JobSiteDashboard from './pages/JobSiteDashboard'
import CompanyProfile from './pages/CompanyProfile'
import OPTracker from './pages/OPTracker';
import SafetyMeeting from './pages/SafetyMeeting';
import ReadyCheck from './pages/ReadyCheck';
import JSADashboard from './pages/JSADashboard';
import JSAForm from './pages/JSAForm'
import DelayLockout from './components/DelayLockout/DelayLockout';
import './App.css';
import EmergencyLockoutPage from './pages/EmergencyLockoutPage';

export default function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path='/' element={<OPTracker/>}/>
          <Route path='/siteinfo' element={<SiteInfo/>}/>
          <Route path='/sitelog' element={<SiteLog/>}/>
          <Route path='/dashboard' element={<JobSiteDashboard/>}/>
          <Route path='/companyprofile' element={<CompanyProfile/>}/>
          <Route path='/safety' element={<SafetyMeeting/>}/>
          <Route path='/ready-check' element={<ReadyCheck/>}/>
          <Route path='/delay-lockout' element={<DelayLockout/>}/>
          <Route path='/emergency-lockout' element={<EmergencyLockoutPage/>}/>
          <Route path='/jsa-dashboard' element={<JSADashboard/>}/>
          <Route path='/jsa-form' element={<JSAForm/>}/>
        </Routes>
      </>
    </Router>
  );
}
