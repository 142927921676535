import React from 'react';
import NavHub from '../components/NavHub/NavHub';
import JSALogs from '../components/JSALogs/JSALogs';

export default function JSADashboard(props) {
    return (
        <>
            <div className='site-log-container'>
                <JSALogs/>
                <NavHub/>
            </div>
        </>
    );
}