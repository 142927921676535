import React, { useState, useEffect }from 'react';
import { createPortal } from 'react-dom'
import { BackIcon, SiteInfoIcon } from '../../assets/icons/icons';
import './style.css'

function ReadyConfirmation({ onClose, onClick }) {
    return (
        <>
            <div className='site-info-bg'>
                <div className='back-icon-container'>
                    <BackIcon onClick={onClose}/>
                </div>
            </div>
        </>
    )
}

export default function ReadyPortal(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    if (!isOpen) {
        document.body.style.removeProperty('overflow')
    }
    else {
        document.body.style.overflow = 'hidden'
    }

    return (
        <>
            <div className="ready-btn" onClick={() => setIsOpen(true)}></div>
            {isOpen && createPortal(
                <ReadyConfirmation onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
                document.getElementById('ready-check')
            )}
        </>
    );
}