import {React,  useEffect, useState } from 'react';
import { StepIcon } from '../assets/icons/icons';
import NavHub from '../components/NavHub/NavHub';
import SiteInfo from '../components/SiteInfo/SiteInfo';
import { createPortal } from 'react-dom';
import EmergencyLockout from '../components/EmergencyLockout/EmergencyLockout';

export default function OPTracker(props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorCode, setErrorCode] = useState(null)
    let [ allSteps, setAllSteps ] = useState([])
    let [ index, setIndex ] = useState(2)
    let [ happeningNow, setHappeningNow ] = useState('')
    let [ previousStep, setPreviousStep ] = useState('')
    let [ currentStep, setCurrentStep ] = useState('')
    let [ nextStep, setNextStep ] = useState('')
    const [ emergency, setEmergency ] = useState(false)

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`)
            if (!response.ok) {
                setLoading(false)
                setError(true)
                const error = `An error occurred: ${response.status}`
                setErrorCode(response.status)
                throw new Error(error)
            }
            const data = await response.json()
            // console.log(data)
            setLoading(false)
            setAllSteps(data)
            // setHappeningNow(data[index].text)
            if (index == allSteps.length - 1) {
                setHappeningNow(data[index].text)
                setPreviousStep(data[index-1].text)
                setNextStep('')
            } else {
                setHappeningNow(data[index].text)
                setNextStep(data[index+1].text)
                if (index === 2) {
                    setPreviousStep('')
                } else {
                    setPreviousStep(data[index-1].text)
                }
            }
            return data
        }
        fetchData()
    }, [index])

    // Functions to make stuff work 

    const IncrementIndex = () => {
        if (index === allSteps.length - 1) {
            setIndex(2)
            setHappeningNow(allSteps[2].text)
            setNextStep(allSteps[3].text)
            console.log(`${happeningNow}`)
        } else {
            setIndex(index+1)
            setHappeningNow(allSteps[index].text)
            console.log(`${index}`)
            console.log(`${happeningNow}`)
            console.log(`${nextStep}`)
        }
    } 

    // function EmergencyConfirmation({ onClose, onClick }) {
    //     const [confirm, setConfirm] =useState(false)
    //     return (
    //         <>
    //             <div className='emergency-check-bg'>
    //                 <div className='delay-check-container'>
    //                     <div className='delay-title-container'>
    //                         <h1 className='delay-title'>What is the reason?</h1>
    //                         <form id='delay-form' className='delay-input-form'>
    //                             <input id='delay-input' className='jsa-input' placeholder='Type...'></input>
    //                         </form>
    //                     </div>
    //                     <div className='ready-check-container'>
    //                         <div id='delay' className='confirm-btn' onClick={event => window.location.href='/delay-lockout'}>
    //                             <span>Confirm</span>
    //                         </div> 
    //                         {/* {confirm && createPortal(
    //                             <DelayLockout onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
    //                             document.getElementById('delay-check')
    //                         )} */}
    //                         <div className='cancel-btn' onClick={onClose}>
    //                             <span>Cancel</span>
    //                         </div> 
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }


    if (loading) {
        return (
            <>
                {/* Loading Page Here */}
                <p>THIS PAGE IS LOADING....</p>
            </>
        )
    } else if (error) {
        return (
            <>
                {/* Error Page Here */}
                <p>ERROR {errorCode}</p>
            </>
        )
    } else {
        return (
            <>  
                {/* <div className='happening-container'>
                    <h1>Happening Now</h1>
                    <p>{happeningNow}</p>
                    <div className="ready-btn" onClick={event => IncrementIndex()}>Ready?</div>
                </div>
                <div className='happening-container'>
                    <h1>Previous Step</h1>
                    <p>{previousStep}</p>
                </div>
                <div className='happening-container'>
                    <h1>Next Step</h1>
                    <p>{nextStep}</p>
                </div> */}
                <div className='main-dashboard'>
                    <div className='site-title-container'>
                        <h1 className='site-title'>Garza 1400</h1>
                        {/* <SiteInfoIcon className='site-info-icon'/> */}
                        <SiteInfo/>
                    </div>
                    {/* <p className='site-run-info'>Well 1: Run 21/30</p> */}
                    <p className='site-run-info'>Well 1: Run 22/30</p>
                    <div className='pizza-tracker-container'>
                        <StepIcon className='step-icon'/>
                        <div className='step-container'>
                            <p className='step-title-alt'>{previousStep}</p>
                            <p className='step-title'>{happeningNow}</p>
                            <p className='step-title-alt'>{nextStep}</p>
                        </div>
                    </div>
                    <div className='actions-container'>
                        <div className='action-btn'>
                            <h2 className='action-title'>Alerts</h2>
                            <p className='action-subtext'>This is an example of an alert. Well site is shutdown until further notice.</p>
                        </div>
                        <div className='action-btn-half-container'>
                            <div className='action-btn-half'>
                                <h2 className='action-title-half' onClick={event => window.location.href='/sitelog'}>Site Log</h2>
                            </div>
                            <div className='action-btn-half'>
                                <h2 className='action-title-half' onClick={event => window.location.href='/jsa-dashboard'}>JSA</h2>
                            </div>
                        </div>
                        <div className='emergency-btn'>
                            <h2 className='emergency-title' onClick={() => { setEmergency(true)}}>EMERGENCY</h2>
                            {emergency && createPortal(
                                <EmergencyLockout onClose={() => setEmergency(false)} onClick={() => setEmergency(false)}/>,
                                document.getElementById('delay-check')
                            )}
                        </div>
                    </div>
                </div>
                <NavHub/>
                {/* <div className="ready-btn" onClick={event => IncrementIndex()}>Ready?</div> */}
            </>
        );
    }
    
}