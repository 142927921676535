// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-jsa-btn {
    margin: 0.5rem 0 1rem 0;
    background-color: var(--pandon-primary);
    border-radius: 20px;
    text-align: center;
    color: white;
    font-weight: 500;
}

.jsa-popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.527);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jsa-popup-title {
    text-align: center;
    font-size: 1.5rem;
    color: var(--pandon-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/JSALogs/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,uCAAuC;IACvC,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,4CAA4C;IAC5C,kCAA0B;YAA1B,0BAA0B;IAC1B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,4BAA4B;AAChC","sourcesContent":[".submit-jsa-btn {\r\n    margin: 0.5rem 0 1rem 0;\r\n    background-color: var(--pandon-primary);\r\n    border-radius: 20px;\r\n    text-align: center;\r\n    color: white;\r\n    font-weight: 500;\r\n}\r\n\r\n.jsa-popup-bg {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    z-index: 1000;\r\n    background-color: rgba(255, 255, 255, 0.527);\r\n    backdrop-filter: blur(5px);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.jsa-popup-title {\r\n    text-align: center;\r\n    font-size: 1.5rem;\r\n    color: var(--pandon-primary);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
