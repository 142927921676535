import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom';
import './style.css'

function JSAPopUp({ onClose, onConfirmNo, onConfirmYes }) {
    return (
        <>
            <div className='jsa-popup-bg'>
                <h1 className='jsa-popup-title'>Has anything changed?</h1>
                <div className='ready-check-container'>
                    <div className='confirm-btn' onClick={onConfirmYes}>
                        <span>Yes</span>
                    </div> 
                    <div className='cancel-btn' onClick={onConfirmNo}>
                        <span>No</span>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default function JSALogs(props) {
    const [info, setInfo] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [jsaRecords, setJsaRecords] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setInfo(data[2]))
        .catch(error => console.error('Error:', error));

        const userId = 1
        // Fetch all JSA Records
        const fetchJSARecords = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jsa?user_id=${userId}`); // Adjust to use actual user_id
                const data = await response.json();
                setJsaRecords(data); // Assuming you have a state to store these
            } catch (error) {
                console.error('Error fetching JSA records:', error);
            }
        };

        fetchJSARecords();
    }, []);
    
    // In the JSALogForm component
   // Example of using navigation
    const navigate = useNavigate();

    const handleNewJSA = () => {
        navigate('/jsa-form', { state: { loadRecentJSA: false } });
    };

    const handleLoadRecentJSA = () => {
        navigate('/jsa-form', { state: { loadRecentJSA: true } });
    };
    

    if (!isOpen) {
        document.body.style.removeProperty('overflow')
    }
    else {
        document.body.style.overflow = 'hidden'
    }

    return (
        <>
            <div className='log-info-container'>
                <h1 className='site-log-title'>JSA Logs</h1>
                <p className='log-name'>Well Site: {info.name}</p>
            </div>
            <div className='log-status-container'>
                <div className='submit-jsa-btn' onClick={event => setIsOpen(true)}>
                    <p className='up-next-status'>Submit New JSA</p>
                </div>
                {isOpen && createPortal(
                    <JSAPopUp onClose={() => setIsOpen(false)} onConfirmNo={handleLoadRecentJSA} onConfirmYes={handleNewJSA}/>,
                    document.getElementById('ready-check')
                )}
                <h2 className='log-status-title'>Past Logs</h2>
            </div>
            <div className='log-container'>
                {jsaRecords.map(record => (
                    <div className='log-btn' key={record.id}>
                        <div>
                            <h2 className='log-title'>Ticket # {record.jsa_data?.ticketNumber || ''}</h2>
                            <p className='log-subtext'>Date: {new Date(record.date_created).toLocaleDateString()}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}