import React, { useState, useEffect } from 'react';
import './style.css'

export default function Logs(props) {
    const [info, setInfo] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setInfo(data[2]))
        .catch(error => console.error('Error:', error));
    }, []);

    return (
        <>
            <div className='log-info-container'>
                <h1 className='site-log-title'>Site Log</h1>
                <p className='log-name'> {info.name}</p>
            </div>
            {/* <div className='log-status-container'>
                <h2 className='log-status'>Current Status: <span>Down for maintanence</span></h2>
            </div> */}
            <div className='log-status-container'>
                <h2 className='log-status-title'>Current Status</h2>
                <div className='up-next-status-container'>
                    <p className='up-next-status'>Down for maintanence</p>
                </div>
            </div>
            <div className='log-container'>
                <div className='log-btn'>
                    <div>
                        <h2 className='log-title'>Pump Down</h2>
                        <p className='log-subtext'>This is an example of an alert. Well site is shutdown until further notice.</p>
                    </div>
                    <div className='log-time-container'>
                        <p className='log-time'>12:31pm</p>
                    </div>
                </div>
                <div className='log-btn'>
                    <div>
                        <h2 className='log-title'>Pump Down</h2>
                        <p className='log-subtext'>This is an example of an alert. Well site is shutdown until further notice.</p>
                    </div>
                    <div className='log-time-container'>
                        <p className='log-time'>12:31pm</p>
                    </div>
                </div>
                <div className='log-btn'>
                    <div>
                        <h2 className='log-title'>Pump Down</h2>
                        <p className='log-subtext'>This is an example of an alert. Well site is shutdown until further notice.</p>
                    </div>
                    <div className='log-time-container'>
                        <p className='log-time'>12:31pm</p>
                    </div>
                </div>
                <div className='log-btn'>
                    <div>
                        <h2 className='log-title'>Pump Down</h2>
                        <p className='log-subtext'>This is an example of an alert. Well site is shutdown until further notice.</p>
                    </div>
                    <div className='log-time-container'>
                        <p className='log-time'>12:31pm</p>
                    </div>
                </div>
            </div>
        </>
    );
}