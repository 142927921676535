import {React,  useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { StepIcon, BackIcon } from '../../assets/icons/icons';
import ReadyPortal from '../ReadyPortal/ReadyPortal';
import DelayLockout from '../DelayLockout/DelayLockout';
import './style.css'

export default function ChecknBalance(props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorCode, setErrorCode] = useState(null)
    let [ allSteps, setAllSteps ] = useState([])
    let [ index, setIndex ] = useState(2)
    let [ happeningNow, setHappeningNow ] = useState('')
    let [ previousStep, setPreviousStep ] = useState('')
    let [ currentStep, setCurrentStep ] = useState('')
    let [ nextStep, setNextStep ] = useState('')

    const [isOpen, setIsOpen] = useState(false);
    const [isDelayOpen, setIsDelayOpen] = useState(false)
    
    if (!isOpen) {
        document.body.style.removeProperty('overflow')
    }
    else {
        document.body.style.overflow = 'hidden'
    }
    
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`)
            if (!response.ok) {
                setLoading(false)
                setError(true)
                const error = `An error occurred: ${response.status}`
                setErrorCode(response.status)
                throw new Error(error)
            }
            const data = await response.json()
            // console.log(data)
            setLoading(false)
            setAllSteps(data)
            // setHappeningNow(data[index].text)
            if (index == allSteps.length - 1) {
                setHappeningNow(data[index].text)
                setPreviousStep(data[index-1].text)
                // Uncomment this part to have an empty part after 
                setNextStep('Well 1: Run 22/30 Start')
                // setNextStep(data[2].text)
            } else {
                setHappeningNow(data[index].text)
                setNextStep(data[index+1].text)
                if (index === 2) {
                    setPreviousStep('')
                } else {
                    setPreviousStep(data[index-1].text)
                }
            }
            return data
        }
        fetchData()
    }, [index])

    // Functions to make stuff work 

    const IncrementIndex = () => {
        if (index === allSteps.length - 1) {
            setIndex(2)
            setHappeningNow(allSteps[2].text)
            setNextStep(allSteps[3].text)
        } else if (index === allSteps.length) {
            setIndex(index+1)
            setHappeningNow(allSteps[index].text)
            setNextStep(allSteps[2].text)
        } else {
            setIndex(index+1)
            setHappeningNow(allSteps[index].text)
        }
    } 

    function ReadyConfirmation({ onClose, onClick }) {
        return (
            <>
                <div className='ready-check-bg'>
                    <div className='ready-check-container'>
                        <div className='confirm-btn' onClick={() => { onClose(); IncrementIndex(); }}>
                            <span>Confirm</span>
                        </div> 
                        <div className='cancel-btn' onClick={onClose}>
                            <span>Cancel</span>
                        </div> 
                    </div>
                </div>
            </>
        )
    }
    function DelayConfirmation({ onClose, onClick }) {
        const [confirm, setConfirm] =useState(false)
        const [delayReason, setDelayReason] = useState('');

        const handleConfirm = () => {
            sessionStorage.setItem('delayReason', delayReason); // Store delay reason
            window.location.href='/delay-lockout';
        };
        return (
            <>
                <div className='delay-check-bg'>
                    <div className='delay-label-container'>
                        <div className='delay-label-outer'>
                            <div className='delay-label-inner'>
                                <h1 className='delay-label-title'>DELAY</h1>
                            </div>
                        </div>
                    </div>
                    <div className='delay-check-container'>
                        <div className='delay-title-container'>
                            <h1 className='delay-title'>What is the reason?</h1>
                            <form id='delay-form' className='delay-input-form'>
                                <input 
                                    id='delay-input' 
                                    className='jsa-input' 
                                    placeholder='Type...'
                                    value={delayReason}
                                    onChange={(e) => setDelayReason(e.target.value)}>
                                </input>
                            </form>
                        </div>
                        <div className='ready-check-container'>
                            <div id='delay' className='confirm-btn' onClick={(e) => handleConfirm()}>
                                <span>Confirm</span>
                            </div> 
                            <div className='cancel-btn' onClick={onClose}>
                                <span>Cancel</span>
                            </div> 
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (loading) {
        return (
            <>
                {/* Loading Page Here */}
                <p>THIS PAGE IS LOADING....</p>
            </>
        )
    } else if (error) {
        return (
            <>
                {/* Error Page Here */}
                <p>ERROR {errorCode}</p>
            </>
        )
    } else {
        return (
            <>
                <div className='ready-screen-outline'>
                    <div className='ready-screen-container'>
                        <div className='happening-container'>
                            <h1 className='happening-title'>Happening Now</h1>
                            <p className='ready-team-name'>Wire Line</p>
                            <div className='happening-status-container'>
                                <p>{happeningNow}</p>
                            </div>
                        </div>
                        <div className='up-next-container'>
                            <h2 className='up-next-title'>Up Next...</h2>
                            <div className='up-next-status-container'>
                                <p className='up-next-status'>{nextStep}</p>
                            </div>
                        </div>
                        <div className="ready-btn" onClick={() => setIsOpen(true)}>READY</div>
                        {isOpen && createPortal(
                            <ReadyConfirmation onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
                            document.getElementById('ready-check')
                        )}
                        <div className="delay-btn" onClick={() => setIsDelayOpen(true)}>DELAY</div>
                        {isDelayOpen && createPortal(
                            <DelayConfirmation onClose={() => setIsDelayOpen(false)} onClick={() => setIsDelayOpen(false)}/>,
                            document.getElementById('delay-check')
                        )}
                    </div>
                </div>
            </>
        );
    }
}