import React from 'react';
import NavHub from '../components/NavHub/NavHub';
import ChecknBalance from '../components/ChecknBalance/ChecknBalance';

export default  function ReadyCheck(props) {
    return (
        <>
            <ChecknBalance/>
            <NavHub/>
        </>
    );
}