// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navhub-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: var(--pandon-primary);
    display: flex;
    justify-content: space-around;
    /* padding: 1rem 0 1rem 0; */
    align-items: center;
    height: 8vh;

}
.home-icon {
    color: black;
    font-weight: 700;
}
.home-icon-filled {
    color: white;
    font-weight: 700;
}

.nav-icon {
    transform: scale(.75);
}`, "",{"version":3,"sources":["webpack://./src/components/NavHub/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,uCAAuC;IACvC,aAAa;IACb,6BAA6B;IAC7B,4BAA4B;IAC5B,mBAAmB;IACnB,WAAW;;AAEf;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".navhub-container {\r\n    width: 100%;\r\n    position: absolute;\r\n    bottom: 0;\r\n    background-color: var(--pandon-primary);\r\n    display: flex;\r\n    justify-content: space-around;\r\n    /* padding: 1rem 0 1rem 0; */\r\n    align-items: center;\r\n    height: 8vh;\r\n\r\n}\r\n.home-icon {\r\n    color: black;\r\n    font-weight: 700;\r\n}\r\n.home-icon-filled {\r\n    color: white;\r\n    font-weight: 700;\r\n}\r\n\r\n.nav-icon {\r\n    transform: scale(.75);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
