import React from 'react';
import NavHub from '../components/NavHub/NavHub';
import Logs from '../components/Logs/Logs';

export default function SiteLog(props) {
    return (
        <>
            <div className='site-log-container'>
                <Logs/>
                <NavHub/>
            </div>
        </>
    );
}