// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delay-title-alt {
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
}

.resolve-btn {
    width: 80%;
    padding: 1rem;
    background-color: var(--pandon-primary);
    text-align: center;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    margin: 1rem;
}

.delay-title-status {
    margin: 0 0 0.5rem 0;
}

.delay-status-container {
    width: 85%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/DelayLockout/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uCAAuC;IACvC,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".delay-title-alt {\r\n    font-size: 1rem;\r\n    font-weight: 300;\r\n    margin: 0;\r\n}\r\n\r\n.resolve-btn {\r\n    width: 80%;\r\n    padding: 1rem;\r\n    background-color: var(--pandon-primary);\r\n    text-align: center;\r\n    border-radius: 10px;\r\n    color: white;\r\n    font-weight: 500;\r\n    margin: 1rem;\r\n}\r\n\r\n.delay-title-status {\r\n    margin: 0 0 0.5rem 0;\r\n}\r\n\r\n.delay-status-container {\r\n    width: 85%;\r\n    height: fit-content;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n    justify-content: center;\r\n    background-color: white;\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
