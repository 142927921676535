// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-info-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-icon-container{
    width: 85%;
    margin: 2rem 0 0 0;
}

.site-info-container {
    width: 85%;
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.site-info-title {
    font-size: 1.25rem;
    margin: 0 0 0.5rem 0;
    font-weight: 700;
}

.site-info-text {
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/SiteInfo/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".site-info-bg {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    z-index: 1000;\r\n    background-color: white;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.back-icon-container{\r\n    width: 85%;\r\n    margin: 2rem 0 0 0;\r\n}\r\n\r\n.site-info-container {\r\n    width: 85%;\r\n    margin: 2rem 0 0 0;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n}\r\n\r\n.site-info-title {\r\n    font-size: 1.25rem;\r\n    margin: 0 0 0.5rem 0;\r\n    font-weight: 700;\r\n}\r\n\r\n.site-info-text {\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
