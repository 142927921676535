// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
    position: relative;
    width: 100px;
}

.dropdown-header {
    padding: 0.6rem;
    background-color: var(--pandon-primary);
    border-radius: 20px;
    text-align: center;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-style: none;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-arrow {
    transform: scale(0.5);
}

.ppe-checkboxes {
    display: none; /* Default state: hidden */
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    width: 100%;
    z-index: 100;
}

/* Show checkboxes when showCheckboxes is true */
.ppe-checkboxes.show {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/MemberInput/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,uCAAuC;IACvC,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa,EAAE,0BAA0B;IACzC,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,qCAAqC;IACrC,WAAW;IACX,YAAY;AAChB;;AAEA,gDAAgD;AAChD;IACI,cAAc;AAClB","sourcesContent":[".dropdown {\r\n    position: relative;\r\n    width: 100px;\r\n}\r\n\r\n.dropdown-header {\r\n    padding: 0.6rem;\r\n    background-color: var(--pandon-primary);\r\n    border-radius: 20px;\r\n    text-align: center;\r\n    color: white;\r\n    font-weight: 500;\r\n    cursor: pointer;\r\n    border-style: none;\r\n    width: max-content;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.dropdown-arrow {\r\n    transform: scale(0.5);\r\n}\r\n\r\n.ppe-checkboxes {\r\n    display: none; /* Default state: hidden */\r\n    position: absolute;\r\n    background: white;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 5px rgba(0,0,0,0.2);\r\n    width: 100%;\r\n    z-index: 100;\r\n}\r\n\r\n/* Show checkboxes when showCheckboxes is true */\r\n.ppe-checkboxes.show {\r\n    display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
