import {React,  useEffect, useState } from 'react';
import { WellIcon } from '../assets/icons/icons';

export default function SiteInfo(props) {
    const [weiner, setWeiner] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setWeiner(data[2]))
        .catch(error => console.error('Error:', error));
    }, []);

    return (
        <>
            <div>
                <WellIcon/>
            </div>
            <div>
                <h1>This is the site info</h1>
                <p>{weiner.name}</p>
                <p>{weiner.address}{weiner.city}{weiner.state}</p>
                <p>{weiner.owner}</p>
            </div>
        </>
    );
}