import React, { useState, useEffect }from 'react';
import { createPortal } from 'react-dom'
import './style.css'
import { BackIcon, CheckIcon, SiteInfoIcon, WellIcon } from '../../assets/icons/icons';

function CompanyManDelayCheck() {
    const [ ready, setReady] =useState(false)

    useEffect(() => {
        console.log('checking company man status')
    },[])
    // real code
    if (!ready) {
        setTimeout(() =>{
            window.location.href='/ready-check'
        } ,5000)
        return (
            <>
                <div className='delay-status-container'>
                    <h2 className='delay-title-status'>Status</h2>
                    <h1 className='delay-title-alt'>Awaiting Company Man Approval</h1>
                </div>
            </>
        )
    } else {
        return (
            <>
                <span onClick={window.location.href='/ready-check'}></span>
            </>
        )
    }
}

export default function DelayLockout(props) {
    const [info, setInfo] = useState({});
    const [checkCM, setCheckCM] = useState(false);
    const [delayReason, setDelayReason] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setInfo(data[2]))
        .catch(error => console.error('Error:', error));

        // Retrieve the delay reason from sessionStorage
        const storedDelayReason = sessionStorage.getItem('delayReason');
        if (storedDelayReason) {
            setDelayReason(storedDelayReason);
        }
    }, []);
    
    if (!checkCM) {
        return (
            <>  
                <div className='delay-check-bg'>
                    <div className='delay-label-container'>
                        <div className='delay-label-outer'>
                            <div className='delay-label-inner'>
                                <h1 className='delay-label-title'>DELAY</h1>
                            </div>
                        </div>
                    </div>
                    <div className='delay-check-container'>
                        <div className='delay-title-container'>
                            <h2 className='delay-title-alt'>Wire Line Delay</h2>
                            <h1 className='delay-title'>{delayReason || "No reason provided"}</h1>
                        </div>
                        <div className='ready-check-container' onClick={() => { setCheckCM(true)}}>
                            <div id='delay' className='resolve-btn'>
                                <span>Resolve</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='delay-check-bg'>
                    <div className='delay-label-container'>
                        <div className='delay-label-outer'>
                            <div className='delay-label-inner'>
                                <h1 className='delay-label-title'>DELAY</h1>
                            </div>
                        </div>
                    </div>
                    <div className='delay-check-container'>
                        <CompanyManDelayCheck/>
                    </div>
                </div>
            </>
        )
    }
}