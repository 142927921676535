import React, { useState, useEffect }from 'react';
import './style.css'
import { eventWrapper } from '@testing-library/user-event/dist/utils';

export function CompanyManEmercencyCheck() {
    const [ confirmEmergency, setConfirmEmergency] =useState(false)

    useEffect(() => {
        console.log('checking company man status')
    },[])
    // real code
    if (!confirmEmergency) {
        setTimeout(() =>{
            // window.location.href='/'
            setConfirmEmergency(true)
        } ,5000)
        return (
            <>
                <div className='delay-check-bg'>
                    <div className='delay-label-container'>
                        <div className='delay-label-outer'>
                            <div className='delay-label-inner'>
                                <h1 className='delay-label-title'>DELAY</h1>
                            </div>
                        </div>
                    </div>
                    <div className='delay-check-container'>
                        <div className='delay-title-container'>
                            <h1 className='delay-title'>Pending Emergency</h1>
                            <h2 className='delay-title-alt'>Awaiting Action Instructions</h2>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        setTimeout(() =>{
            window.location.href='/'
        } ,5000)
        return (
            <>
                <div className='emergency-check-bg'>
                    <div className='delay-label-container'>
                        <div className='delay-label-outer'>
                            <div className='emergency-label-inner'>
                                <h1 className='emergency-label-title'>EMERGENCY</h1>
                            </div>
                        </div>
                    </div>
                    <div className='delay-check-container'>
                        <div className='delay-title-container'>
                            <h1 className='emergency-lockout-title'>STOP OPERATION</h1>
                            <h2 className='emergency-title-alt'>Emergency Type</h2>
                            <p className='emergency-title-text'>*emergency classification set by CM*</p>
                            <h3 className='emergency-title-alt'>Emergency Instructions</h3>
                            <p className='emergency-title-text'>*instructions set by CM*</p>
                        </div>
                        {/* <div className='ready-check-container'>
                            <div id='delay' className='resolve-btn'>
                                <span>Resolve</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </>
        )
    }
}

export default function EmergencyLockout({ onClick, onClose}) {
    const [info, setInfo] = useState({});
    const [checkCM, setCheckCM] = useState(false);
    const [emergencyReason, setEmergencyReason] = useState('');

    // const handleConfirm = () => {
    //     sessionStorage.setItem('emergencyReason', emergencyReason); // Store delay reason
    //     window.location.href='/emergency-lockout';
    // };

    const confirmDelay = () => {
        setTimeout(() => {
            window.location.href='/emergency-lockout'
        }, 5000)
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/wellsite`)
        .then(response => response.json())
        .then(data => setInfo(data[2]))
        .catch(error => console.error('Error:', error));
    }, []);
    
    return (
        <>
            <div className='emergency-check-bg'>
                <div className='delay-check-container'>
                    <div className='delay-title-container'>
                        <h1 id='pending-emergency-title' className='emergency-lockout-title'>EMERGENCY</h1>
                        <h2 id='emergency-title-alt' className='delay-title-alt'>Please Describe the Emergency</h2>
                        <form id='delay-form' className='delay-input-form'>
                            <input 
                                id='delay-input' 
                                className='jsa-input' 
                                placeholder='Type...'
                                value={emergencyReason}
                                onChange={(e) => setEmergencyReason(e.target.value)}>
                            </input>
                        </form>
                    </div>
                    <h2 className='delay-title-alt'>Hold "Confirm" button for 5sec</h2>
                    <div className='ready-check-container'>
                        <div 
                            id='emergency' 
                            className='confirm-btn' 
                            onClick={event => confirmDelay()}>
                            <span>Confirm</span>
                        </div> 
                        {/* {confirm && createPortal(
                            <DelayLockout onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}/>,
                            document.getElementById('delay-check')
                        )} */}
                        <div className='cancel-btn' onClick={onClose}>
                            <span>Cancel</span>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

