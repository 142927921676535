// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.log-info-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 2rem 0 1rem 0.5rem;
}

.site-log-title {
    margin: 0;
}

.log-name {
    margin: 0.25rem 0 0 0;
    font-weight: 500;
}
.log-status-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.log-status-title {
    font-size: 14px;
    margin: 0 0 0.5rem 0.25rem;
}

.log-status {
    font-size: 0.8rem;
    margin: 0.25rem 0 0.25rem 0;
}

.log-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    height: 65vh;
    overflow-y: auto;
}

.log-btn {
    padding: 1rem;
    margin: 1rem 0 1rem 0;
    border-radius: 11px;
    background-color: #b0d1d0;
    display: flex;

}

.log-title {
    margin: 0 0 0 0;
    color: black;
    text-align: start;
    font-size: 1rem;
    font-weight: 500;
}

.log-subtext {
    font-size: 0.75rem;
    margin: 0.25rem 0 0 0;
}

.log-time-container {
    display: flex;
}

.log-time {
    margin: 0;
    font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Logs/style.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;;AAEjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB","sourcesContent":["\r\n.log-info-container {\r\n    width: 85%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    margin: 2rem 0 1rem 0.5rem;\r\n}\r\n\r\n.site-log-title {\r\n    margin: 0;\r\n}\r\n\r\n.log-name {\r\n    margin: 0.25rem 0 0 0;\r\n    font-weight: 500;\r\n}\r\n.log-status-container {\r\n    width: 85%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n}\r\n\r\n.log-status-title {\r\n    font-size: 14px;\r\n    margin: 0 0 0.5rem 0.25rem;\r\n}\r\n\r\n.log-status {\r\n    font-size: 0.8rem;\r\n    margin: 0.25rem 0 0.25rem 0;\r\n}\r\n\r\n.log-container{\r\n    width: 85%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 65vh;\r\n    overflow-y: auto;\r\n}\r\n\r\n.log-btn {\r\n    padding: 1rem;\r\n    margin: 1rem 0 1rem 0;\r\n    border-radius: 11px;\r\n    background-color: #b0d1d0;\r\n    display: flex;\r\n\r\n}\r\n\r\n.log-title {\r\n    margin: 0 0 0 0;\r\n    color: black;\r\n    text-align: start;\r\n    font-size: 1rem;\r\n    font-weight: 500;\r\n}\r\n\r\n.log-subtext {\r\n    font-size: 0.75rem;\r\n    margin: 0.25rem 0 0 0;\r\n}\r\n\r\n.log-time-container {\r\n    display: flex;\r\n}\r\n\r\n.log-time {\r\n    margin: 0;\r\n    font-size: 0.75rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
